import { Button } from '@optra/kit';

import CameraListItem from 'components/camera-list-item';
import EmptyContentMessage from 'components/empty-content-message';
import List from 'components/list';
import Message from 'components/message';
import ModalBody from 'components/modal-body';
import { useDevice } from 'queries';

export default function DeviceDetailCameras({ deviceId, onClose }) {
  const {
    data,
    isLoading: fetching,
    error: fetchError,
    refetch,
  } = useDevice(
    deviceId,
    `
      id
      sensors {
        count
        data {
          id
          sensor {
            id
            name
            ip
          }
        }
      }
    `,
  );

  return (
    <ModalBody size="lg">
      {fetchError && (
        <Message variant="danger" title="Couldn't Load Cameras">
          {fetchError.message}
        </Message>
      )}

      {!fetching && !data?.device?.sensors?.data?.length && (
        <EmptyContentMessage
          title="No Cameras Connected"
          renderAction={() => (
            <Button icon="Plus" to="./create" state={{ fromModal: true }}>
              Add Camera
            </Button>
          )}
        />
      )}

      {!fetching && data?.device?.sensors?.data?.length > 0 && (
        <div className="flex items-center justify-end mb-3">
          <Button
            to="./create"
            state={{ fromModal: true }}
            icon="Plus"
            size="xs"
            variant="tertiary"
          >
            Add Camera
          </Button>
        </div>
      )}

      <List loading={fetching}>
        {data?.device?.sensors?.data?.length > 0 &&
          data?.device?.sensors?.data?.map(sensor => (
            <CameraListItem
              key={sensor?.sensor?.id}
              id={sensor?.sensor?.id}
              to={`./${sensor?.sensor?.id}/edit`}
              name={sensor?.sensor?.name}
              onDelete={refetch}
            />
          ))}
      </List>
    </ModalBody>
  );
}
