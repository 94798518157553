import cx from 'classnames';
import { isFinite as _isFinite, isEmpty } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';

import { Text } from '@optra/kit';

import Feature, { useFeature } from 'components/feature';
import FeatureToggle from 'components/feature-toggle';
import Input from 'components/input';
import KeyValueInput from 'components/key-value-input';
import Label from 'components/label';
import MultiInput from 'components/multi-input';
import { useCurrentUser } from 'queries';

export const FIELD_NAMES = {
  sound: 'Sound',
  removableMedia: 'Removable Media',
  storage: 'Storage',
  cameras: 'USB Cameras',
  port: 'Web UI',
  hdmi: 'HDMI',
  hostName: 'Host Name',
  endpointAliases: 'Network Endpoint Alias',
  portBindings: 'Port Bindings',
  tmpfs: 'TMPFS',
  shmSize: 'SHM Size',
  hostNetworking: 'Host Networking',
  privileged: 'Privileged',
  capAdd: 'Add Kernel Capabilities',
  capDrop: 'Drop Kernel Capabilities',
  binds: 'Binds',
  dockerInDocker: 'Docker In Docker',
  devices: 'Devices',
  cx2000IRRemote: 'CX2000 IR Remote Control',
  usbSerialConverter: 'USB Serial Converters (FTDI)',
  gpio: 'GPIOs',
  led: 'LED Control',
  cx2000VideoAcceleration: 'CX2000 Video Acceleration',
  writableRootFS: 'Writable RootFS Size',
  mountVolumes: 'Volumes with Size Limit',
};

const PORT_BINDING_RANGE = [0, 65535];

const portIsInRange = port =>
  (!isNaN(port) && port >= PORT_BINDING_RANGE[0] && port <= PORT_BINDING_RANGE[1]) ||
  `Port number ${port} is outside the valid range (${PORT_BINDING_RANGE[0]} - ${PORT_BINDING_RANGE[1]})`;

export default function SkillPrivilegesFields({ visible, loading = false, form }) {
  const {
    watch,
    formState: { errors },
    control,
    register,
    defaultValues,
    setValue,
    clearErrors,
  } = form;

  const [currentUser] = useCurrentUser();

  // The state of the toggle is identified initially from values only
  // But if values are reset, we trigger an effect to track that state
  function useFieldToggle(fieldName, _identifierFn) {
    // eslint-disable-next-line
    const identifierFn = useCallback(_identifierFn, []);
    const [isOpen, setIsOpen] = useState(identifierFn(defaultValues?.[fieldName]));
    const currentFieldValue = watch(fieldName);
    useEffect(() => {
      setIsOpen(identifierFn(currentFieldValue));
    }, [currentFieldValue, identifierFn]);

    return [isOpen, setIsOpen];
  }

  const [hasStorage, setHasStorage] = useFieldToggle('storage', value => value?.length > 0);
  const [hasCapAdd, setHasCapAdd] = useFieldToggle('capAdd', value => value?.length > 0);
  const [hasCapDrop, setHasCapDrop] = useFieldToggle('capDrop', value => value?.length > 0);
  const [hasExposedUIPort, setHasExposedUIPort] = useFieldToggle('port', value =>
    _isFinite(parseInt(value)),
  );
  const [hasHostName, setHasHostName] = useFieldToggle('hostName', value => !isEmpty(value));
  const [hasEndpointAlias, setHasEndpointAlias] = useFieldToggle(
    'endpointAliases',
    value => value?.length > 0,
  );
  const [hasPortBindings, setHasPortBindings] = useFieldToggle(
    'portBindings',
    value => value?.length > 0,
  );
  const [hasTmpfs, setHasTmpfs] = useFieldToggle('tmpfs', value => value?.length > 0);
  const [hasShmSize, setHasShmSize] = useFieldToggle('shmSize', value =>
    _isFinite(parseInt(value)),
  );
  const [hasWritableRootFS, setHasWritableRootFS] = useFieldToggle('writableRootFS', value =>
    _isFinite(parseInt(value)),
  );
  const [hasAddedDevice, setHasAddedDevice] = useFieldToggle(
    'addedDevice',
    value => value?.length > 0,
  );
  const [hasBinds, setHasBinds] = useFieldToggle('binds', value => value?.length > 0);
  const [hasMountVolumes, setHasMountVolumes] = useFieldToggle(
    'mountVolumes',
    value => value?.length > 0,
  );

  const hostNetworkingEnabled = useFeature('hostNetworking');
  const volumeLimitsEnabled = useFeature('volumeLimits');

  return (
    <div className={cx(visible ? 'block' : 'hidden')}>
      <FeatureToggle
        icon="SpeakerHigh"
        title="Sound"
        description="Allow the skill to utilize the device's sound port to play sounds."
        readOnly={loading}
        {...register('devices.sound')}
      />
      <FeatureToggle
        icon="Disc"
        title="Removable Media"
        description="Allows the skill to use removable media."
        readOnly={loading}
        {...register('removableMedia')}
      />
      <FeatureToggle
        icon="Database"
        title="Volumes"
        description="Allow the skill to access the device's filesystem."
        readOnly={loading}
        checked={hasStorage}
        expanded={hasStorage}
        onChange={() => {
          setHasStorage(hasStorage => !hasStorage);
          if (hasStorage) {
            setValue('storage', []);
            clearErrors('storage');
          }
        }}
        errors={[{ storage: errors?.storage }]}
      >
        <Controller
          render={({ field }) => (
            <KeyValueInput {...field} keyLabel="Volume Name" valueLabel="Mount Path" />
          )}
          name="storage"
          control={control}
          disabled={loading}
          defaultValue={defaultValues?.storage || []}
          rules={{
            validate: {
              volumeIsAlphaNumeric: values => {
                if (values?.length || hasStorage) {
                  const p = new RegExp('^[a-zA-Z0-9]*$');
                  return (
                    values?.every(v => p.test(v.key)) === true || 'Volume name must be alphanumeric'
                  );
                }
                return true;
              },
              pathIsDirectory: values => {
                if (values?.length || hasStorage) {
                  const p = new RegExp('^/|(/[w-]+)+$');
                  return (
                    values?.every(v => p.test(v.value)) === true ||
                    'Mount path must be in the shape of /directory'
                  );
                }
                return true;
              },
            },
          }}
        />
      </FeatureToggle>
      <FeatureToggle
        icon="Camera"
        title="Connected Cameras"
        description="Allows the skill to use USB and CSI cameras."
        readOnly={loading}
        {...register('devices.cameras')}
      />
      <Feature feature="deviceTunnel">
        <FeatureToggle
          icon="Globe"
          title="Web UI"
          description="Expose a web-based user-interface via a port of your choosing."
          readOnly={loading}
          checked={hasExposedUIPort}
          expanded={hasExposedUIPort}
          onChange={() => {
            setHasExposedUIPort(hasExposedUIPort => !hasExposedUIPort);
            if (hasExposedUIPort) {
              setValue('port', '');
              clearErrors('port');
            }
          }}
          errors={[{ port: errors?.port }]}
        >
          <Controller
            render={({ field }) => (
              <div className="flex flex-col items-start justify-between">
                <div className="space-y-2">
                  <Label htmlFor="port">Port Number</Label>
                  <Input type="number" {...field} value={field.value || ''} />
                </div>
                <div className="mt-1 flex flex-row">
                  {field.value && (
                    <Text color="muted" size="xs" className="normal-case font-normal mt-1">
                      Exposing port {field.value}. The customer selected port will be available via
                      OPTRA_SKILL_WEB_PORT.
                    </Text>
                  )}
                </div>
              </div>
            )}
            name="port"
            control={control}
            disabled={loading}
            defaultValue={_isFinite(parseInt(defaultValues?.port)) || ''}
            rules={{
              validate: hasExposedUIPort ? portIsInRange : {},
            }}
          />
        </FeatureToggle>
      </Feature>
      <FeatureToggle
        icon="MonitorPlay"
        title="HDMI"
        description="Allow the skill to utilize the device's HDMI port to play video."
        readOnly={loading}
        {...register('devices.hdmi')}
      />
      <FeatureToggle
        icon="IdentificationCard"
        title="Hostname"
        description="Set a Hostname for the skill."
        readOnly={loading}
        checked={hasHostName}
        expanded={hasHostName}
        onChange={() => {
          setHasHostName(hasHostName => !hasHostName);
          if (hasHostName) {
            setValue('hostName', '');
            clearErrors('hostName');
          }
        }}
      >
        <Controller
          render={({ field }) => (
            <div className="flex items-end justify-between">
              <div className="space-y-2">
                <Label htmlFor="hostName">Hostname</Label>
                <Input type="text" {...field} value={field.value || ''} />
              </div>
            </div>
          )}
          name="hostName"
          control={control}
          disabled={loading}
          defaultValue={defaultValues?.hostName}
        />
      </FeatureToggle>
      {hostNetworkingEnabled && (
        <FeatureToggle
          icon="ShareNetwork"
          title="Host Networking"
          description="Set the skill to use Host Networking"
          readOnly={loading}
          beta
          {...register('hostNetworking')}
        />
      )}
      <FeatureToggle
        icon="Detective"
        title="Network Endpoint Alias"
        description="Set an Alias for the skill on the internal network.  "
        readOnly={loading}
        checked={hasEndpointAlias}
        expanded={true}
        onChange={() => {
          setHasEndpointAlias(hasEndpointAlias => !hasEndpointAlias);
          if (hasEndpointAlias) {
            setValue('endpointAliases', []);
            clearErrors('endpointAliases');
          }
        }}
      >
        {hasEndpointAlias && (
          <MultiInput name="endpointAliases" fields={{ alias: { label: 'Alias' } }} form={form} />
        )}
      </FeatureToggle>
      <FeatureToggle
        icon="LineSegment"
        title="Port Bindings"
        description="Allow the skill to bind one of its ports to a port on the external network."
        readOnly={loading}
        checked={hasPortBindings}
        expanded={true}
        onChange={() => {
          setHasPortBindings(hasPortBindings => !hasPortBindings);
          if (hasPortBindings) {
            setValue('portBindings', []);
            clearErrors('portBindings');
          }
        }}
        errors={errors?.portBindings}
      >
        {hasPortBindings && (
          <MultiInput
            name="portBindings"
            fields={{
              containerPort: {
                label: 'Container Port',
                type: 'number',
                validate: {
                  containerPortIsInRange: portIsInRange,
                },
                options: {
                  required: true,
                },
              },
              protocol: {
                label: 'Protocol',
                type: 'enum',
                enumValues: ['tcp', 'udp'],
                options: {
                  required: true,
                },
              },
              hostPort: {
                label: 'Host Port',
                type: 'number',
                validate: {
                  hostPortIsInRange: portIsInRange,
                },
                options: {
                  required: true,
                },
              },
            }}
            form={form}
          />
        )}
      </FeatureToggle>
      <FeatureToggle
        icon="FolderSimpleDotted"
        title="Tmpfs"
        description="Allows the skill to create temporary file systems."
        checked={hasTmpfs}
        readOnly={loading}
        expanded={true}
        onChange={() => {
          setHasTmpfs(hasTmpfs => !hasTmpfs);
          if (hasTmpfs) {
            setValue('tmpfs', []);
            clearErrors('tmpfs');
          }
        }}
        errors={errors?.tmpfs}
      >
        {hasTmpfs && (
          <MultiInput
            name="tmpfs"
            fields={{
              containerDevicePath: {
                label: 'Container Device Path',
                validate: {
                  pathIsDirectory: value => {
                    const p = new RegExp('^/|(/[w-]+)+$');
                    return p.test(value) || 'Device Path must be in the shape of /directory';
                  },
                },
                options: {
                  required: true,
                },
              },
              sizeBytes: {
                label: 'Size (blank for unlimited)',
                type: 'number',
                placeholder: 'bytes',
                options: {
                  required: false,
                },
              },
            }}
            form={form}
          />
        )}
      </FeatureToggle>
      {currentUser?.isSysAdmin && (
        <>
          <FeatureToggle
            icon="Lockers"
            title="Docker-in-Docker"
            description="Allows the skill to use Docker within Docker."
            readOnly={loading}
            {...register('dockerInDocker')}
          />
          <FeatureToggle
            icon="ShieldStar"
            title="Privileged"
            description="Allows the skill to run as Privileged."
            readOnly={loading}
            {...register('privileged')}
          />

          <FeatureToggle
            icon="ShieldCheck"
            title="Add Kernel Capabilities"
            description="Allows the skill to add kernel capabilities."
            readOnly={loading}
            checked={hasCapAdd}
            expanded={hasCapAdd}
            onChange={() => {
              setHasCapAdd(current => !current);
              if (hasCapAdd) {
                setValue('capAdd', []);
                clearErrors('capAdd');
              }
            }}
          >
            {hasCapAdd && (
              <MultiInput
                name="capAdd"
                fields={{
                  capability: {
                    label: 'Capability',
                    placeholder: 'e.g., SYS_ADMIN',
                    options: {
                      required: true,
                    },
                  },
                }}
                form={form}
              />
            )}
          </FeatureToggle>

          <FeatureToggle
            icon="ShieldCheck"
            title="Drop Kernel Capabilities"
            description="Allows the skill to drop kernel capabilities."
            readOnly={loading}
            checked={hasCapDrop}
            expanded={hasCapDrop}
            onChange={() => {
              setHasCapDrop(current => !current);
              if (hasCapDrop) {
                setValue('capDrop', []);
                clearErrors('capDrop');
              }
            }}
          >
            {hasCapDrop && (
              <MultiInput
                name="capDrop"
                fields={{
                  capability: {
                    label: 'Capability',
                    placeholder: 'e.g., SYS_ADMIN',
                    options: {
                      required: true,
                    },
                  },
                }}
                form={form}
              />
            )}
          </FeatureToggle>
          <FeatureToggle
            icon="DeviceMobile"
            title="Device"
            description="Allows the skill to use device specified by device major number."
            readOnly={loading}
            checked={hasAddedDevice}
            expanded={hasAddedDevice}
            onChange={() => {
              setHasAddedDevice(current => !current);
              if (hasAddedDevice) {
                setValue('addedDevice', []);
                clearErrors('addedDevice');
              }
            }}
          >
            {hasAddedDevice && (
              <MultiInput
                name="addedDevice"
                fields={{
                  majorNumber: {
                    label: 'Device Major Number',
                    type: 'number',
                    placeholder: 'e.g., 0-255',
                    validate: value => {
                      const intValue = parseInt(value, 10);
                      return (
                        (intValue >= 0 && intValue <= 255) ||
                        'Major number must be between 0 and 255'
                      );
                    },
                    options: { required: true },
                  },
                }}
                form={form}
              />
            )}
          </FeatureToggle>
          <FeatureToggle
            icon="FolderSimpleDotted"
            title="Binds"
            description="Allows the skill to access the device's file system."
            readOnly={loading}
            checked={hasBinds}
            expanded={hasBinds}
            onChange={() => {
              setHasBinds(current => !current);
              if (hasBinds) {
                setValue('binds', []);
                clearErrors('binds');
              }
            }}
          >
            {hasBinds && (
              <MultiInput
                name="binds"
                fields={{
                  hostPath: {
                    label: 'Path in Host',
                    placeholder: '/path/in/host',
                    options: { required: true },
                    validate: value => {
                      const isPath = /^\/([\w-]+\/?)+$/;
                      return isPath.test(value) || 'Host path must be a valid absolute path';
                    },
                  },
                  containerPath: {
                    label: 'Path in Container',
                    placeholder: '/path/in/container',
                    options: { required: true },
                    validate: value => {
                      const isPath = /^\/([\w-]+\/?)+$/;
                      return isPath.test(value) || 'Container path must be a valid absolute path';
                    },
                  },
                }}
                form={form}
              />
            )}
          </FeatureToggle>
        </>
      )}
      <FeatureToggle
        icon="HardDrives"
        title="SHM Size"
        description="Allows the skill to set its Linux Shared Memory size."
        checked={hasShmSize}
        expanded={hasShmSize}
        readOnly={loading}
        onChange={() => {
          setHasShmSize(hasShmSize => !hasShmSize);
          if (hasShmSize) {
            setValue('shmSize', '');
            clearErrors('shmSize');
          }
        }}
        errors={errors?.shmSize?.type === 'shmSizeIsNumeric' && errors?.shmSize}
      >
        <Controller
          render={({ field }) => (
            <div className="flex items-end justify-between my-4">
              <div className="space-y-2">
                <Label htmlFor="shmSize">Size (in bytes)</Label>
                <Input
                  type="number"
                  {...field}
                  onChange={evt => field.onChange(parseFloat(evt.target.value))}
                  value={field.value || ''}
                />
              </div>
            </div>
          )}
          name="shmSize"
          control={control}
          disabled={loading}
          defaultValue={defaultValues?.shmSize}
          rules={{
            required: hasShmSize,
          }}
        />
      </FeatureToggle>
      <FeatureToggle
        icon="Siren"
        title="CX2000 IR Remote Control"
        description="Allows the skill to receive key codes from an IR Remote Control"
        readOnly={loading}
        {...register('cx2000IRRemote')}
      />
      <FeatureToggle
        icon="Usb"
        title="USB Serial Converters (FTDI)"
        description="Allows the skill to use USB serial FTDI devices"
        readOnly={loading}
        {...register('usbSerialConverter')}
      />
      <FeatureToggle
        icon="Cpu"
        title="GPIOs"
        description="Allows the skill to use GPIOs"
        readOnly={loading}
        {...register('gpio')}
      />
      <FeatureToggle
        icon="Lightbulb"
        title="LED Control"
        description="Allows the skill to control LEDs"
        readOnly={loading}
        {...register('led')}
      />
      <FeatureToggle
        icon="VideoCamera"
        title="CX2000 Video Acceleration"
        description="Allows the skill to use CX2000 Video Acceleration"
        readOnly={loading}
        {...register('cx2000VideoAcceleration')}
      />
      {volumeLimitsEnabled && (
        <FeatureToggle
          icon="HardDrives"
          title="Writable Root FS Limit"
          description="Limit the size of the root file system."
          checked={hasWritableRootFS}
          expanded={hasWritableRootFS}
          readOnly={loading}
          onChange={() => {
            setHasWritableRootFS(hasWritableRootFS => !hasWritableRootFS);
            if (hasWritableRootFS) {
              setValue('writableRootFS', '');
              clearErrors('hasWritableRootFS');
            }
          }}
          errors={
            errors?.writableRootFS?.type === 'writableRootFSIsNumeric' && errors?.writableRootFS
          }
        >
          <Controller
            render={({ field }) => (
              <div className="flex items-end justify-between my-4">
                <div className="space-y-2">
                  <Label htmlFor="writableRootFS">Size (in kB)</Label>
                  <Input
                    type="number"
                    min="256"
                    {...field}
                    onChange={evt => field.onChange(parseFloat(evt.target.value))}
                    value={field.value || ''}
                  />
                </div>
              </div>
            )}
            name="writableRootFS"
            control={control}
            disabled={loading}
            defaultValue={defaultValues?.writableRootFS}
            rules={{
              required: hasWritableRootFS,
              validate: value => {
                if (!hasWritableRootFS) return true;
                const intValue = parseInt(value, 10);
                return _isFinite(intValue) && intValue >= 256
                  ? true
                  : 'Size must be greater than 256k';
              },
            }}
          />
        </FeatureToggle>
      )}
      {volumeLimitsEnabled && (
        <FeatureToggle
          icon="HardDrive"
          title="Volumes with Size Limit"
          description="Allow the skill to create and access volumes with a size limit on the device's filesystem. Valid only on devices with XFS for Docker storage."
          readOnly={loading}
          checked={hasMountVolumes}
          expanded={hasMountVolumes}
          onChange={() => {
            setHasMountVolumes(current => !current);
            if (hasMountVolumes) {
              setValue('mountVolumes');
              clearErrors('mountVolumes');
            }
          }}
        >
          {hasMountVolumes && (
            <MultiInput
              name="mountVolumes"
              fields={{
                volumeName: {
                  label: 'Volume Name',
                  placeholder: 'Enter volume name',
                  options: { required: true },
                },
                mountPath: {
                  label: 'Mount Path',
                  placeholder: '/path/in/container',
                  validate: value => {
                    const isPath = /^\/([\w-]+\/?)+$/;
                    return isPath.test(value) || 'Must be a valid path starting with /';
                  },
                  options: { required: true },
                },
                sizeLimit: {
                  label: 'Size Limit (e.g., 100m)',
                  placeholder: 'Enter size limit (e.g., 100m)',
                  validate: value =>
                    /^\d+(k|m|g)$/i.test(value) || 'Size limit must be specified in k, m, or g',
                  options: { required: true },
                },
              }}
              form={form}
            />
          )}
        </FeatureToggle>
      )}
    </div>
  );
}
