import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { Button } from '@optra/kit';

import Message from 'components/message';
import { useModalContext } from 'components/modal';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import UserFormFields from 'components/user-form-fields';
import { api, q } from 'config/api';
import { useInputFocus } from 'hooks';
import { useCurrentUser, useRoles } from 'queries';

export default function CreateUser() {
  const { handleClose } = useModalContext();
  const {
    handleSubmit: onSubmit,
    register,
    formState: { errors },
    setFocus,
  } = useForm({
    defaultValues: {
      email: '',
    },
  });
  const [error, setError] = useState();
  const [selectedRoles, setSelectedRoles] = useState([]);
  useInputFocus(setFocus, 'email');

  const roles = useRoles();
  const [currentUser] = useCurrentUser();

  const qc = q.useQueryClient();
  const createUser = q.useMutation({
    mutationFn: form =>
      api(
        `mutation createOrganizationMember($form: createOrganizationMemberForm!) {
          createOrganizationMember(form: $form) {
            id
          }
        }`,
        { form },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['organizationMembers'] });
      handleClose();
    },
    onError(err) {
      setError(err);
    },
  });

  const handleSubmit = onSubmit(form => {
    setError(null);

    if (selectedRoles.length < 1) {
      setError({ message: 'Please choose a role!' });
      return;
    }

    const domain = email => email?.split('@')?.[1]?.toLowerCase() || '';
    const currentUserEmailDomain = domain(currentUser.email);
    const formUserEmailDomain = domain(form.email);

    if (currentUserEmailDomain !== formUserEmailDomain) {
      if (
        // For an admin-only feature, a `confirm` is fine for now.
        // eslint-disable-next-line
        !confirm(
          `The email address you entered has the domain "${formUserEmailDomain}" which is different than your organization's domain: "${currentUserEmailDomain}"\r\n\r\nAre you sure this is correct?`,
        )
      ) {
        return;
      }
    }

    createUser.mutate({
      ...form,
      roles: selectedRoles,
    });
  });

  return (
    <ModalInner as="form" onSubmit={handleSubmit}>
      <ModalTitle title="Create User" icon="Users" loading={createUser.isPending} />
      <ModalBody>
        {error && (
          <Message variant="danger" title="Couldn't Create User">
            {error.message}
          </Message>
        )}
        <UserFormFields
          register={register}
          errors={errors}
          loading={createUser.isPending}
          roles={roles?.data?.model?.list}
          selectedRoles={selectedRoles}
          onSelectRole={setSelectedRoles}
        />
      </ModalBody>
      <ModalFooter>
        <Button type="submit" size="xl" loading={createUser.isPending}>
          Save
        </Button>
      </ModalFooter>
    </ModalInner>
  );
}
