import { find } from 'lodash';
import { useState, Fragment } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { Button, DROPDOWN_MENU_PLACEMENT } from '@optra/kit';

import { useFeature } from 'components/feature';
import FeatureToggle from 'components/feature-toggle';
import Input from 'components/input';
import Label from 'components/label';
import Message from 'components/message';
import { useModalContext } from 'components/modal';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import OrganizationSelect from 'components/organization-select';
import ValidationError from 'components/validation-error';
import { api, q, useOnSuccess } from 'config/api';
import { useInputFocus, useItemNotFound } from 'hooks';
import ItemNotFound from 'modals/item-not-found';
import { useOrganization } from 'queries';

export default function AdminEditOrganization() {
  const { organizationId } = useParams();
  const { handleClose } = useModalContext();
  const {
    handleSubmit: onSubmit,
    control,
    register,
    formState: { errors },
    setFocus,
    reset,
    watch,
  } = useForm({
    defaultValues: {
      name: '',
      parentId: '$$NONE',
      features: [],
    },
  });

  const DEVICE_SKILL_SYNC_LIMIT_FEATURE_NAME = 'deviceSkillSyncLimit';
  const SKILL_BUILDER_FEATURE_NAME = 'skillBuilder';
  const DEVICE_SYNC_LIMIT_FEATURE_NAME = 'deviceSyncLimit';
  const watchFeatures = watch('features');
  const watchDeviceSkillSyncLimit = find(watchFeatures, {
    name: DEVICE_SKILL_SYNC_LIMIT_FEATURE_NAME,
  });
  const watchDeviceSyncLimit = find(watchFeatures, { name: DEVICE_SYNC_LIMIT_FEATURE_NAME });
  const watchSkillBuilder = find(watchFeatures, { name: SKILL_BUILDER_FEATURE_NAME });
  const enterpriseSkillBuilderEnabled = useFeature('enterpriseSkillBuilder', 'global');

  const [error, setError] = useState();
  useInputFocus(setFocus, 'name');

  const { fields: featureFields } = useFieldArray({
    control,
    name: 'features',
  });

  const {
    isLoading: fetching,
    isSuccess,
    error: fetchError,
    data,
  } = useOrganization(organizationId);

  useOnSuccess(
    () => {
      const {
        organization,
        deviceSkillSyncLimit,
        deviceSyncLimit,
        edgeImpulseEnterpriseAllowance,
      } = data;
      reset({
        name: organization?.name,
        parentId: organization?.parentOrganizations?.data?.at(0)?.parent?.id ?? '$$NONE',
        features: organization?.features?.data?.map?.(ft => ({
          id: ft.feature?.id,
          enabled: ft.enabledAsConfigured,
          label: ft.feature?.label,
          name: ft.feature?.id,
        })),
        entitlements: {
          deviceSkillSyncLimit: deviceSkillSyncLimit.value,
          deviceSyncLimit: deviceSyncLimit.value,
          edgeImpulseEnterpriseAllowance: edgeImpulseEnterpriseAllowance.value,
        },
      });
    },
    { isSuccess },
    [data, reset],
  );

  const qc = q.useQueryClient();
  const updateOrganization = q.useMutation({
    mutationFn: _form => {
      const form = {
        ..._form,
        entitlements: Object.entries(_form.entitlements).map(([entitlement, value]) => ({
          entitlement,
          value: parseInt(value),
        })),
      };

      return api(
        `mutation updateOrganization($form: updateOrganizationForm!) {
          updateOrganization(form: $form) { id }
        }`,
        { form },
      );
    },
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['organization', organizationId] });
      handleClose();
    },
    onError(err) {
      setError(err);
    },
  });

  const removeOrganization = q.useMutation({
    mutationFn: id =>
      api(
        `mutation removeOrganization($id: ID!) {
          removeOrganization(id: $id) {
            id
          }
        }`,
        { id },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['organization', organizationId] });
      qc.invalidateQueries({ queryKey: ['organizations'] });
      handleClose();
    },
  });

  const handleSubmit = onSubmit(form => {
    setError(null);
    updateOrganization.mutate({
      id: organizationId,
      ...form,
      features: form.features.filter(feat => feat.enabled).map(feat => feat.name),
    });
  });

  const handleDelete = event => {
    event.stopPropagation();
    event.preventDefault();
    if (window.confirm('Are you sure you want to delete this organization?')) {
      removeOrganization.mutate(organizationId);
    }
  };

  const loading = fetchError || fetching || updateOrganization.isPending;

  const itemNotFound = useItemNotFound({
    fetching,
    id: data?.organization?.id,
  });
  if (itemNotFound) {
    return <ItemNotFound id={organizationId} type="Organization" />;
  }
  return (
    <ModalInner as="form" onSubmit={handleSubmit}>
      <ModalTitle
        title="Edit Organization"
        icon="Buildings"
        loading={loading}
        renderActions={() => (
          <Button
            variant="secondary"
            size="xs"
            onClick={handleDelete}
            loading={removeOrganization.isPending}
            icon="Trash"
          >
            Delete
          </Button>
        )}
      />
      <ModalBody className="space-y-6">
        {fetchError && (
          <Message variant="danger" title="Couldn't Load Organization">
            {fetchError.message}
          </Message>
        )}
        {error && (
          <Message variant="danger" title="Couldn't Update Organization">
            {error.message}
          </Message>
        )}

        <div className="flex flex-row items-center space-x-2">
          <Label htmlFor="parentOrganization">Parent</Label>
          <Controller
            name="parentId"
            control={control}
            disabled={updateOrganization.isPending}
            render={({ field: { ref, ...field } }) => (
              <OrganizationSelect
                placement={DROPDOWN_MENU_PLACEMENT.BOTTOM_LEFT}
                placeholderText="No Parent"
                allOptionText="No Parent"
                allOptionValue="$$NONE"
                allOptionIcon="LinkBreak"
                filters={{
                  features: { createChildOrganizations: true },
                  excludeOrganizationId: organizationId,
                }}
                {...field}
              />
            )}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="name">Organization Name</Label>
          <Input
            type="text"
            {...register('name', { required: 'Please enter a name.' })}
            readOnly={loading}
          />
          <ValidationError errors={errors} name="name" />
        </div>

        <div className="space-y-2">
          <Label htmlFor="features">Enabled Features</Label>
          {featureFields.map((field, index) => {
            if (!field) return null;
            const isEnterpriseAllowanceEnabled =
              featureFields[index]?.name === SKILL_BUILDER_FEATURE_NAME &&
              watchSkillBuilder?.enabled &&
              enterpriseSkillBuilderEnabled;
            const isDeviceSkillSyncLimitEnabled =
              featureFields[index]?.name === DEVICE_SKILL_SYNC_LIMIT_FEATURE_NAME &&
              watchDeviceSkillSyncLimit?.enabled;
            const isDeviceSyncLimitEnabled =
              featureFields[index]?.name === DEVICE_SYNC_LIMIT_FEATURE_NAME &&
              watchDeviceSyncLimit?.enabled;
            return (
              <Fragment key={field.id}>
                <FeatureToggle
                  title={field.label}
                  expanded={
                    isEnterpriseAllowanceEnabled ||
                    isDeviceSkillSyncLimitEnabled ||
                    isDeviceSyncLimitEnabled
                  }
                  {...register(`features.${index}.enabled`)}
                >
                  {isEnterpriseAllowanceEnabled && (
                    <div className="space-y-2">
                      <Label htmlFor="entitlements.edgeImpulseEnterpriseAllowance">
                        Enterprise Allowance
                      </Label>
                      <Input
                        type="number"
                        {...register('entitlements.edgeImpulseEnterpriseAllowance')}
                        readOnly={loading}
                      />
                      <ValidationError
                        errors={errors}
                        name="entitlements.edgeImpulseEnterpriseAllowance"
                      />
                    </div>
                  )}
                  {isDeviceSkillSyncLimitEnabled && (
                    <div className="space-y-2">
                      <Label htmlFor="entitlements.deviceSkillSyncLimit">
                        Device Skill Sync Limit
                      </Label>
                      <Input
                        type="number"
                        {...register('entitlements.deviceSkillSyncLimit')}
                        readOnly={loading}
                      />
                      <ValidationError errors={errors} name="entitlements.deviceSkillSyncLimit" />
                    </div>
                  )}
                  {isDeviceSyncLimitEnabled && (
                    <div className="space-y-2">
                      <Label htmlFor="entitlements.deviceSyncLimit">Device Sync Limit</Label>
                      <Input
                        type="number"
                        {...register('entitlements.deviceSyncLimit')}
                        readOnly={loading}
                      />
                      <ValidationError errors={errors} name="entitlements.deviceSyncLimit" />
                    </div>
                  )}
                </FeatureToggle>
                <input type="hidden" {...register(`features.${index}.id`)} />
              </Fragment>
            );
          })}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button type="submit" size="xl" loading={loading}>
          Save
        </Button>
      </ModalFooter>
    </ModalInner>
  );
}
