import { useNavigate } from 'react-router-dom';

import { Button, Tooltip, UnreadDot } from '@optra/kit';

import { api, q } from 'config/api';
import { useDeviceEws } from 'queries';

export default function EWSButton(props) {
  const { deviceId, disabled } = props;
  const icon = 'Sliders';
  const text = 'Control Panel';

  const navigate = useNavigate();
  const redirectUrl = 'ews';

  const { data, isLoading } = useDeviceEws(deviceId, {
    refetchInterval: 10000,
  });
  const device = data?.device;

  const qc = q.useQueryClient();
  const openTunnel = q.useMutation({
    mutationFn: () =>
      api(
        `mutation openDeviceTunnel($form: openDeviceTunnelForm!) {
          tunnel: openDeviceTunnel(form: $form) {
            id
            url
          }
        }`,
        { form: { deviceId, type: 'ews' } },
      ),
    onSuccess(r) {
      qc.invalidateQueries({ queryKey: ['device', deviceId, 'ews'] });
      navigate(redirectUrl);
    },
  });

  if (!device?.capabilities?.tunneling) {
    return (
      <Tooltip label="Firmware Update Required">
        <span>
          <Button size="xs" variant="tertiary" icon={icon} disabled>
            {text} <UnreadDot className="absolute top-0 right-0" />
          </Button>
        </span>
      </Tooltip>
    );
  }

  const isWaiting = isLoading || openTunnel.isPending;
  const remainingTime = (device?.tunnel?.expiresAt || 0) - Date.now();
  const totalTime = (device?.tunnel?.expiresAt || 0) - (device?.tunnel?.createdAt || 0);
  const progressUntilClose = Math.max(remainingTime / Math.max(totalTime, 1), 0);
  const isOpen = progressUntilClose > 0;

  // Show a warning if the device isn't connected and there isn't an error
  // (because the tooltip for the error badge conflicts with this warning).
  const displayConnectionWarning = !device?.adminConnected && !openTunnel.isError;

  return (
    <Tooltip
      label={
        displayConnectionWarning
          ? 'Device not fully connected - opening tunnel may fail'
          : undefined
      }
      disabled={!displayConnectionWarning}
    >
      <span>
        <Button
          size="xs"
          variant="tertiary"
          icon={isOpen ? null : icon}
          loading={openTunnel.isPending}
          hideChildrenWhenLoading={false}
          disabled={disabled || isWaiting}
          showProgress={isOpen}
          progress={progressUntilClose}
          error={openTunnel.isError ? 'Unable to open tunnel' : null}
          onClick={() => {
            if (isWaiting) return;
            if (isOpen) {
              navigate(redirectUrl);
              return;
            }
            openTunnel.mutate();
          }}
        >
          {text}
        </Button>
      </span>
    </Tooltip>
  );
}
